import React, { useEffect, useState } from 'react';
import api from '../Services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import '../App.css'
import { Button, ModalHeader } from 'react-bootstrap';
import NavBarMine from '../Components/NavBarMine';


function ReportPage() {

    const [activeSections, setActiveSections] = useState([])
    const [votacoes, setVotacoes] = useState({})

    const [step, setStep] = useState(0)
    const [votacaoSelecionada, setVotacaoSelecionada] = useState(0)

    const [show, setShow] = useState(false)

    useEffect(() => {
        LoadData()
    }, [])

    function LoadData() {
        api.get("sessions/get-closed-sessions", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(x => {
            setActiveSections(x.data)

        })
            .catch(x => console.log(x))


    }

    function LoadVotacao(x) {
        api.get("sessions/get-closed-session-by-id/" + x.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(x => {
            setVotacoes(x.data)
            console.log(x.data)
            setStep(0)
        })
            .catch(x => console.log(x))
    }

    async function ConfirmVote(e)
    {
        e.preventDefault()
        await setShow(true)
    }

    return (
        <div className='tela'>
            <NavBarMine />
            <div className='container text-center d-flex flex-column align-items-center w-100'>
                <h1 className='my-5'>Votações Fechadas</h1>

                <div className='row w-50'>

                    {activeSections.map(x => {
                        return (
                            <div onClick={p => LoadVotacao(x)} className={`btn btn-primary cp mb-3`}>Sessão: <strong> {x.titulo} </strong></div>
                        )
                    })}

                </div>



                {votacoes.items != null &&

                    <div className='d-flex my-5 '>
                        {votacoes.items.map((x, index) => {
                            console.log(x)
                            return (
                                <div className='text-center d-flex justify-content-center'>
                                    <div className='d-flex align-items-end'>
                                        <div className='d-flex flex-column text-center align-items-center' >
                                            {x.cargo.titulo}
                                        <div onClick={p => setStep(x.id)} className={`rounded-circle cp circle ${x.id == step ? 'bg-info' : ''}`}>
                                            
                                        </div>
                                        </div>
                                        {
                                            index < votacoes.items.length-1 &&
                                            <hr className='hrCircle'></hr>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }


                <form onSubmit={ConfirmVote} className='form'>

                    {votacoes.items != null && votacoes.items.filter(x => x.id == step).map(x => {
                        return (
                            <div>
                                <h3>{x.cargo.titulo}</h3>
                                {x.candidatos.map(c => {
                                    return (
                                        <div className='p-3 mb-2 card'>
                                            <p>{c.nome}</p>
                                            <p>Votos: {c.votos}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </form>
            </div>

            <Modal show={show} onHide={x => setShow(false)}>
                <ModalHeader>
                    Você confirma seu voto?
                </ModalHeader>

                <Modal.Body>
                    *Dados da votação*
                </Modal.Body>

                <Modal.Footer>
                    <button onClick={x => setShow(false)} className='btn btn-danger'>Cancelar</button> 
                    <button className='btn btn-success'>Enviar</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ReportPage;