import React, { useEffect, useState } from 'react';
import api from '../Services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import { Navigate, useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import LogoColorida from '../Images/Ativo4.svg'

function LoginPage() {

    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')

    const [show, setShow] = useState(false)
    const [errorMsg, setErrorMsg] = useState('d-none')
    const navigate = useNavigate();
    
    const Login = (e) => {
        e.preventDefault();

        api.post('auth/login', {
            email: email,
            senha: password
          }).then(x => {
            if (x.status == 200)
            {
                window.localStorage.setItem('token',x.data.token)
            }
          })
          .then(x => {
            navigate("/home")
          })
          .catch(x => {

            setErrorMsg('')
          })
    }

    const ShowFogotPass = () => {
        setShow(true)
    }

   
    return (
        <div>
            <div className='tela d-flex align-items-center justify-content-center'>
                <form className='form w-50 text-center d-flex flex-column align-items-center' onSubmit={Login}>
                    <img className=' my-3 imgLogo' src={LogoColorida} />
                    <input required className='form-control my-3' placeholder='Email' type='email' value={email} onChange={x => setEmail(x.target.value)} />
                    <input required className='form-control' placeholder='Senha' type='password' value={password} onChange={x => setPassword(x.target.value)} />
                    {/* <a onClick={ShowFogotPass} className='my-1 align-self-start cp'>Esqueci minha senha</a> */}
                    <button type='submit' className='btn mt-2 btn-success w-25 mt-3'>Entrar</button> 

                    <p className={`mt-2 text-danger ${errorMsg}`}>Usuário ou senha inválidos</p>
                </form>
            </div>

            <Modal show={show} onHide={x => setShow(false)}>
                <Modal.Header>
                    Esqueci minha senha
                </Modal.Header>
                <Modal.Body>
                    <form className='form d-flex flex-column'>
                        <label>
                            Digite o seu email
                            <input type='email' required className='form-control' ></input>
                        </label>

                        
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type='text' onClick={x => setShow(false)} className='btn btn-danger'>Cancelar</button> 
                    <button type='submit'  className='btn btn-success'>Enviar</button> 
                </Modal.Footer>
            </Modal>

           
        </div>
    );
}

export default LoginPage;   