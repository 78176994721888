import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import LoginPage from './Pages/LoginPage';
import VotingPage from './Pages/VotingPage';
import ReportPage from './Pages/ReportPage';


const token = window.localStorage.getItem("token")

var router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/home",
    element: <VotingPage />,
  },
  {
    path: '/fechadas',
    element: <ReportPage />
  }
]);


// var router = createBrowserRouter(
//   createRoutesFromElements(
//     <Route path='/' element={<LoginPage />} >
//         <Route path='home' element={<VotingPage />} />
//         <Route path='fechadas' element={<ReportPage />} />
//     </Route>

//   )
// )

// if (token == null)
// {
//   router = createBrowserRouter([
//     {
//       path: "/",
//       element: <LoginPage />,
//     }
//   ]);
// }


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
