import { useState } from "react";
import { Modal, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import logo from "../Images/Ativo4.svg"

function NavBarMine() {
    const [show, setShow] = useState(false);

    function showModal(){
        setShow(true)
    }

    function LogOut()
    {
        window.localStorage.clear();
        window.location.pathname = "/"
    }
    return ( 
        <Navbar>
            <div className='container'>
                <Navbar.Brand><img src={logo} className="img-fluid imgLogoHeader" /></Navbar.Brand>

                <Nav className='ms-auto'>
                    <Nav.Link><Link className={`${window.location.pathname == "/home" ? 'text-info' : ''}`} to='/home'> Votacoes Ativas</Link></Nav.Link>
                    <Nav.Link><Link className={`${window.location.pathname == "/fechadas" ? 'text-info' : ''}`} to='/fechadas'>Votacoes Fechadas</Link></Nav.Link>
                    <Nav.Link onClick={LogOut}>Sair</Nav.Link>
                    {/* <Nav.Link className='text-dark' onClick={x => showModal()}>Alterar Senha</Nav.Link> */}
                </Nav>
            </div>


            <Modal show={show} onHide={x => setShow(false)}>
                <Modal.Header>
                    Alterar sua senha
                </Modal.Header>
                <Modal.Body>
                    <form className='form'>
                        <input className='form-control' placeholder="Digite sua senha atual"></input>
                        <input className='form-control my-3' placeholder="Digite sua nova senha"></input>
                        <input className='form-control' placeholder="Confirme sua nova senha"></input>

                    </form>
                </Modal.Body>

                <Modal.Footer>
                    <button onClick={x => setShow(false)} className='btn btn-danger'>Cancelar</button> 
                    <button className='btn btn-success'>Enviar</button>
                </Modal.Footer>
            </Modal>
        </Navbar>
     );
}

export default NavBarMine
