import React, { useEffect, useState } from 'react';
import api from '../Services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import '../App.css'
import { Button, ModalHeader } from 'react-bootstrap';
import NavBarMine from '../Components/NavBarMine';

import '../App.css'

function VotingPage() {

    const [activeSections, setActiveSections] = useState([])
    const [votacoes, setVotacoes] = useState({})

    const [step, setStep] = useState(0)

    const [show, setShow] = useState(false)
    const [votacaoSelecionada, setVotacaoSelecionada] = useState(0);

    useEffect(() => {
        LoadData()

        SendActivity()
    }, [])


    useEffect(() => {
        var form = document.getElementById("formVote")
    
            for (let i = 0; i < form.length; i++) {
                if (form[i].type == "checkbox" && form[i].checked == true)
                {
                    form[i].checked = false;
                }
            }
    },[step])


    function SendActivity()
    {
        api.post("http://172.16.43.24:100/wsa-voting-backend/api/v1/activity/send-activity",{},{
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        })

        setTimeout(() => {
            SendActivity()
        }, 3000);
    }
    function LoadData() {
        api.get("sessions/get-active-sessions", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(x => {
            setActiveSections(x.data)

        })
            .catch(x => console.log(x))


    }

    function LoadVotacao(x) {


        api.get("sessions/get-active-session-by-id/" + x.id, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(p => {
            setVotacoes(p.data)
            setVotacaoSelecionada(x)
            
        })
            .catch(x => console.log(x))
       

            setTimeout(() => {
                LoadVotacao(x)
            }, 2000);
    }



    async function ConfirmVote(e)
    {
        e.preventDefault()
        

        var form = document.getElementById("formVote")
        var count = 0;

        for (let i = 0; i < form.length; i++) {
            if (form[i].type == "checkbox" && form[i].checked == true)
            {
                count++;
            }
        }
        console.log(votacoes)
        console.log(votacoes.items.filter(x => x.id == step)[0].cargo.qtdCadeiras)
        var cadeiras = votacoes.items.filter(x => x.id == step)[0].cargo.qtdCadeiras;
        if (cadeiras < count)
        {
            window.alert("A quantidade maxima de cadeiras é " + cadeiras)
            return;
        }

        await setShow(true)
    }

    function Votar()
    {
        var form = document.getElementById("formVote")

        const body = []

        for (let i = 0; i < form.length; i++) {
            if (form[i].type == "checkbox" && form[i].checked == true)
            {
                body.push({"candidatoId": parseInt(form[i].value)})
            }
        }

        console.log(body);
        api.post("sessions/vote-by-item-id/"+step,{data: (body)},{
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).catch(x =>console.log(x))

        setShow(false)
        setStep(0)
    }

    return (
        <div className='tela'>
            <NavBarMine />
            <div className='container text-center d-flex flex-column align-items-center w-100'>
                <h1 className='my-5'>Votações Ativas</h1>

                <div className='row w-50'>

                    {activeSections.map(x => {
                        return (
                            <div onClick={p => LoadVotacao(x)} className='btn btn-primary cp mb-3'>Sessão: <strong> {x.titulo} </strong>
                                <small> (Clique para votar)</small>
                            </div>
                        )
                    })}

                </div>



                {votacoes.items != null &&

                    <div className='d-flex my-5 '>
                        {votacoes.items.map((x, index) => {
                             return (
                                <div className='text-center'>
                                    <div className='d-flex'>
                                        <button onClick={p => setStep(x.id)} disabled={(x.jaVotei == false) ? false: true}  className={`btn  text-light ${x.id == step ? 'bg-info' : 'btn-dark'} ${x.jaVotei == true ? 'bg-success' : ''} ${x.ativo == false ? "d-none" : ""}`} >
                                            {x.jaVotei == true ? 'Votado' : 'Iniciar'}
                                        </button>
                                        {
                                            index < votacoes.items.filter(x => x.ativo == true).length-1 &&
                                            <hr className='hrCircle'></hr>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }


                <form id='formVote' onSubmit={ConfirmVote} className='form'>

                    {votacoes.items != null && votacoes.items.filter(x => x.id == step).map(x => {
                        return (
                            <div>
                                <h3>{x.cargo.titulo}</h3>
                                {x.candidatos.map(c => {
                                    return (
                                        <div>
                                            <label className='form-check-label my-2'>
                                                <input value={c.id} className='form-check-input' type='checkbox' />
                                                {c.nome}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}

                    <button type='submit' className={`btn btn-primary my-5 w-100 ${step == 0 ? 'd-none' : ''}`}>Votar</button>
                </form>
            </div>

            <Modal show={show} onHide={x => setShow(false)}>
                <ModalHeader>
                    Você confirma seu voto?
                </ModalHeader>

                <Modal.Body>
                    *Dados da votação*
                </Modal.Body>

                <Modal.Footer>
                    <button onClick={x => setShow(false)} className='btn btn-danger'>Cancelar</button> 
                    <button onClick={x => Votar()} className='btn btn-success'>Enviar</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default VotingPage;